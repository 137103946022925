// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"
import { showModalSuccess } from "../../../../redux/modalTypeSuccess"
import { hideModalCustom } from "../../../../redux/modalTypeCustom"

const urlBase = "api/providers"

const initialState = {
    data: [],
    provider: null, // current provider selected, show details page
    suggestions: [],
    defaultValues: {},
    currentPage: 1,
    params: {}, // all filters
    meta: {},
    total: 0,
    rowsPerPage: 10,
    loading: false,
    catalogSelected: null,
    policy: null,
    policyLoading: false
  }

  export const updateProvider = createAsyncThunk(
    "providersCatalog/update",
    async ({id, payload, url = null}, {dispatch}) => {
      const response = await api.put(`api/providers/${id}`, payload)
  
      // success handler
      if (response.data.status === 'success') {
        if (url) dispatch(showModalSuccess({message: response.data.message, url}))
      }
  
      return {
        data: response.data
      }
    }
  )

export const getProviders = createAsyncThunk(
  "providersCatalog/get",
  async (params, {getState}) => {
    const state = getState()

    const { productSelected } = state.products
    const { businessLineSelected } = state.businessLines
      
    params.product = productSelected?.value
    params.business_line = businessLineSelected?.id

    const response = await api.get(`${urlBase}`, { params })
    return {
      params,
      data: response.data
    }
  }
)

export const getProviderById = createAsyncThunk(
  "providersCatalog/getById",
  async (providerId) => {
    const response = await api.get(`/api/providers/${providerId}`)
    return {
      data: response.data
    }
  }
)

export const getProvidersSuggestions = createAsyncThunk(
  "providersCatalog/getSuggestions",
  async (params, {getState}) => {
    const state = getState()
    const { productSelected } = state.products
    params.product = productSelected?.value
    const response = await api.get('api/providers/suggestions', { params })
    return {
      params,
      data: response.data
    }
  }
)

export const deleteProvider = createAsyncThunk(
  "providersCatalog/delete",
  async (providerId, {dispatch}) => {
    const response = await api.post(`api/delete_provider/${providerId}`)

    if (response.data.status === 'success') {
      dispatch(hideModalCustom())
      dispatch(showModalSuccess({message: response.data.message, url:  `/catalogs/providers`}))
      dispatch(getProviders({page: 1}))
    }
    return {
      params,
      data: response.data
    }
  }
)

export const disableProvider = createAsyncThunk(
  "providersCatalog/disable",
  async (providerId, {dispatch}) => {
    const response = await api.delete(`api/providers/${providerId}`)

    if (response.data.status === 'success') {
      dispatch(hideModalCustom())
      dispatch(showModalSuccess({message: response.data.message, url:  `/catalogs/providers`}))
      dispatch(getProviders({page: 1}))
    }
    return {
      params,
      data: response.data
    }
  }
)

export const enableProvider = createAsyncThunk(
  "providersCatalog/enable",
  async (providerId, {dispatch}) => {
    const response = await api.patch(`api/providers/${providerId}`, {
      status: 'enabled'
    })

    if (response.data.status === 'success') {
      dispatch(hideModalCustom())
      dispatch(showModalSuccess({message: response.data.message, url: '/catalogs/providers'}))
      dispatch(getProviders({page: 1}))
    }

    return {
      params,
      data: response.data
    }
  }
)

export const getPolicyByProvider = createAsyncThunk(
  "getPolicy/byProvider",
  async ({provider, currency}) => {
    const response = await api.get(`api/policy_types/${provider}/${currency}`)

    return {
      data: response.data
    }
  }
)

export const getPolicyByProviderAP = createAsyncThunk(
  "getPolicy/byProviderAP",
  async ({provider, currency, plan}) => {
    const response = await api.get(`api/policy_types/${provider}/${currency}/${plan}`)
    
    return {
      data: response.data
    }
  }
)

export const getCatalogByProvider = createAsyncThunk('providersCatalog/catalogByProvider', async (params) => {
  const response = await api.get(params.url, {params})
  return {
    params,
    data: response.data
  }
})

export const providersSlice = createSlice({
  name: "providersCatalog",
  initialState,
  reducers: {
    cleanDefaultValues: (state) => {
      state.defaultValues = {}
    },
    setCatalogSelected: (state, action) => {
      state.catalogSelected = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProviders.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getProviders.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getProviders.fulfilled, (state, action) => {
      state.data = action.payload.data.data
      state.currentPage = action.payload.data.meta.current_page
      state.params = action.payload.params
      state.meta = action.payload.data.meta
      state.total = action.payload.data.meta.total
      state.rowsPerPage = action.payload.data.meta.per_page
      state.loading = false
      state.provider = null
    })
    builder.addCase(getProvidersSuggestions.fulfilled, (state, action) => {
      state.suggestions = action.payload.data
    })
    builder.addCase(getProviderById.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getProviderById.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getProviderById.fulfilled, (state, action) => {
      state.loading = false
      state.provider = action.payload.data.data
    })
    builder.addCase(getCatalogByProvider.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCatalogByProvider.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getCatalogByProvider.fulfilled, (state, action) => {
      state.data = action.payload.data.data
      state.currentPage = action.payload.data.meta.current_page
      state.params = action.payload.params
      state.meta = action.payload.data.meta
      state.total = action.payload.data.meta.total
      state.rowsPerPage = action.payload.data.meta.per_page
      state.loading = false
    })
    builder.addCase(getPolicyByProvider.fulfilled, (state, action) => {
      state.policy = action.payload.data.data
      state.policyLoading = false
    })
    builder.addCase(getPolicyByProvider.pending, (state) => {
      state.policyLoading = true
    })
    builder.addCase(getPolicyByProvider.rejected, (state) => {
      state.policyLoading = false
    })
    builder.addCase(getPolicyByProviderAP.fulfilled, (state, action) => {
      state.policy = action.payload.data.data
      state.policyLoading = false
    })
    builder.addCase(getPolicyByProviderAP.pending, (state) => {
      state.policyLoading = true
    })
    builder.addCase(getPolicyByProviderAP.rejected, (state) => {
      state.policyLoading = false
    })
  }
})

export const {setCatalogSelected} = providersSlice.actions

export default providersSlice.reducer

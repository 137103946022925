// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"
import { showModalSuccess } from "../../../../redux/modalTypeSuccess"

const urlBase = "api/excluded_goods"

export const getExcludedGoods = createAsyncThunk(
  "excludedGoods/get",
  async (params, { getState }) => {
    const state = getState()
    const { productSelected } = state.products
    const { businessLineSelected } = state.businessLines
    params.business_line = businessLineSelected?.id
    params.product = productSelected?.value
    const response = await api.get(`${urlBase}`, { params })
    return {
      params,
      data: response.data
    }
  }
)

export const getSuggestions = createAsyncThunk(
  "excludedGoods/getSuggestions",
  async () => {
    const response = await api.get(`${urlBase}/suggestions`)
    return {
      data: response.data
    }
  }
)

export const getExcludedGoodById = createAsyncThunk(
  "excludedGoods/edit",
  async (id) => {
    const response = await api.get(`${urlBase}/${id}`)
    return {
      data: response.data
    }
  }
)

export const editExcludedGood = createAsyncThunk(
  "excludedGoods/update",
  async ({ id, data }, { dispatch }) => {
    const response = await api.put(`${urlBase}/${id}`, data)
    const url = `/catalogs/excluded_goods`
    dispatch(showModalSuccess({ message: response.data.message, url }))
    return {
      data: response.data
    }
  }
)

export const createExcludedGood = createAsyncThunk(
  "excludedGoods/store",
  async (payload, { dispatch, getState }) => {
    const state = getState()
    const { productSelected } = state.products
    const { businessLineSelected } = state.businessLines
    payload.business_line = businessLineSelected?.id
    payload.producto = productSelected?.value
    const response = await api.post(`${urlBase}`, payload)
    const url = `/catalogs/excluded_goods`
    dispatch(showModalSuccess({ message: response.data.message, url }))
    return {
      params,
      data: response.data
    }
  }
)

export const deleteExcludedGood = createAsyncThunk(
  "excludedGoods/delete",
  async (id, { dispatch }) => {
    const response = await api.delete(`${urlBase}/${id}`)
    const url = `/catalogs/excluded_goods`
    dispatch(showModalSuccess({ message: response.data.message, url }))
    await dispatch(getExcludedGoods({ page: 1 }))
    return {
      data: response.data
    }
  }
)

export const excludedGoodSlice = createSlice({
  name: "excludedGoods",
  initialState: {
    data: [],
    suggestions: [],
    defaultValues: {},
    currentPage: 1,
    params: {},
    meta: {},
    total: 0
  },
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(getExcludedGoods.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.currentPage = action.payload.data.current_page
      state.params = action.payload.params
      state.meta = action.payload.data.meta
      state.total = action.payload.data.meta.total
    })
    builder.addCase(createExcludedGood.fulfilled, (state, action) => {
      state.data = action.payload.data.data
    })
    builder.addCase(getExcludedGoodById.fulfilled, (state, action) => {
      const payload = action.payload.data.data
      state.data = action.payload.data.data
      state.defaultValues = {
        concepto: payload.concept
      }
    })
    builder.addCase(editExcludedGood.fulfilled, () => {
      //
    })
    builder.addCase(getSuggestions.fulfilled, (state, action) => {
      state.suggestions = action.payload.data
    })
  }
})

export default excludedGoodSlice.reducer
